import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { InputText } from "../../../Components/InputComponents";
import {
  ButtonContained,
  ButtonOutlined,
} from "../../../Components/ButtonComponents";
import HRIS from "../../../../src/logo_HRIS.svg"
import { useStyles } from "../LoginStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

const LoginView = ({
  accountCheck,
  setAccountCheck,
  showPassword,
  username,
  setUsername,
  email2,
  setEmail2,
  pw,
  setPw,
  kode,
  setKode,
  handleChangePw,
  onSubmit,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box>
        {/* <Box display="flex" className={classes.boxValidasiBtn}>
          <Box className={classes.widthBoxText}>
            <Typography style={{ fontSize: "12px" }}>
              Validasi Akun Karyawan Baru
            </Typography>
          </Box>
          <Link style={{ textDecoration: "none" }} to="/validasi-akun">
            <ButtonOutlined text={"Validasi"} />
          </Link>
        </Box> */}
        <Box className="center">
          <img className={classes.IconMitra} src={HRIS} alt="" style={{ maxWidth: 280, minWidth: 220 }} />
          <Box pb={6} className={classes.mainTextBox}>
          </Box>
          <form onSubmit={onSubmit}>
            <Box className={classes.mainBox}>
              <Typography className={classes.title}>
                {accountCheck ? "Cek Akun" : null}
              </Typography>

              <Box className={accountCheck ? classes.pt3em : null}>
                {accountCheck ? (
                  <>
                    <Typography
                      style={{ margin: "10px" }}
                      className={classes.text}
                    >
                      Jika Anda menemui masalah dengan akun Anda, ketik alamat
                      email Anda dan klik tombol cek di bawah.
                    </Typography>
                    <Box style={{ paddingTop: "5%" }}></Box>
                    <InputText
                      type={"email"}
                      onChange={(e) => {
                        setEmail2(e.target.value);
                      }}
                      value={email2}
                      id="email"
                      name="email"
                      className={classes.width95}
                      label={"Email"}
                      required={true}
                    />
                  </>
                ) : (
                  <>
                    <InputText
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      type={"NIK"}
                      value={username}
                      id="nik"
                      className={classes.width95}
                      name="nik"
                      label={"NIK"}
                      required={true}
                      autocomplete={false}
                    />
                    <Box style={{ paddingTop: "5%" }}></Box>
                    <InputText
                      onChange={(e) => {
                        setPw(e.target.value);
                      }}
                      value={pw}
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className={classes.width95}
                      label={"Kata sandi"}
                      required={true}
                      autocomplete={false}
                    />
                    <Box style={{ paddingTop: "5%" }}></Box>
                    <InputText
                      onChange={(e) => {
                        setKode(e.target.value);
                      }}
                      type={"text"}
                      value={kode}
                      id="text"
                      className={classes.width95}
                      name="kode_organisasi"
                      label={"Kode Organisasi"}
                      required={true}
                      autocomplete={false}
                    />
                  </>
                )}
              </Box>

              {accountCheck ? null : (
                <Grid container>
                  <Grid xs={6}>
                    <FormControlLabel
                      style={{ paddingLeft: "5px" }}
                      className={classes.text}
                      control={
                        <Checkbox
                          size="small"
                          style={{ fontSize: "10px" }}
                          // checked={state.checkedA}
                          onChange={handleChangePw}
                          name="checkedA"
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "12px" }}>
                          Lihat kata sandi
                        </Typography>
                      }
                    />
                  </Grid>
                  {/* <Grid xs={6}>
                    <Typography
                      onClick={() => setAccountCheck(true)}
                      style={{
                        paddingTop: "10px",
                        paddingRight: "10px",
                        textAlign: "right",
                      }}
                      className={classes.text}
                    >
                      Lupa password?
                    </Typography>
                  </Grid> */}
                </Grid>
              )}
              <Box
                pt={3}
                style={{ paddingBottom: "5px" }}
                className={classes.center}
              >
                <div className={classes.wrapper}>
                  <ButtonContained
                    disabled={isLoading ? true : null}
                    type={"submit"}
                    className={isLoading ? classes.btnDisable : classes.btn}
                    text={accountCheck ? "Cek" : "Masuk"}
                  />
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Box className={classes.textLoginG}>
        <Typography
          style={{
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "2.5",
          }}
        >
          Powered by PT. Mustika Jaya Lestari
        </Typography>
      </Box>
    </>
  );
};

export default LoginView;
